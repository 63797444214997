<template>
  <section>
    <!-- Loading -->

    <template>
      <!-- Loading -->
      <div v-if="!usersLoaded" class="loading apollo">Loading...</div>

      <div v-else-if="users && users.length > 0">
        <v-card-title>
          Users
          <v-spacer></v-spacer>
          <v-checkbox
            class="filter-checkbox mr-4"
            value
            v-model="activated"
            v-if="isAdmin || isClientAdmin"
            label="Activated"
          ></v-checkbox>
          <v-select
            class="filter-input mr-2"
            single-line
            hide-details
            v-if="isAdmin"
            :items="
              !customers
                ? [
                    {
                      text: 'All Clients',
                      value: null
                    }
                  ]
                : [
                    {
                      text: 'All Clients',
                      value: null
                    }
                  ].concat(
                    customers.map(item => {
                      return {
                        text: item.name,
                        value: parseInt(item.id)
                      };
                    })
                  )
            "
            v-model="customer"
            label="All Clients"
          ></v-select>
          <v-select
            class="filter-input mr-2"
            single-line
            v-if="isAdmin"
            hide-details
            :items="
              !roles
                ? [
                    {
                      text: 'All Roles',
                      value: null
                    }
                  ]
                : [
                    {
                      text: 'All Roles',
                      value: null
                    }
                  ].concat(roles)
            "
            v-model="role"
            label="All Roles"
          ></v-select>
          <v-text-field
            v-model="search"
            class="filter-input"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="users"
          class="elevation-1"
          :footer-props="{ 'items-per-page-options': [15, 30, 50, 100, -1] }"
          :items-per-page="30"
        >
          <template v-slot:top>
            <!-- {{editedItem}}
              {{defaultItem}}-->
            <v-toolbar flat color="white">
              <!-- <v-toolbar-title>My CRUD</v-toolbar-title> -->
              <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
              <v-spacer></v-spacer>
              <v-dialog v-model="dialog" max-width="500px" persistent>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    >Add User</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title>
                    <span class="headline">{{ formTitle }}</span>
                  </v-card-title>

                  <v-card-text>
                    <v-form ref="form" v-model="valid" lazy-validation>
                      <v-container>
                        <v-row>
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.email"
                              label="Email"
                              hide-details="auto"
                              :rules="requiredRules"
                            ></v-text-field>
                          </v-col>
                          <!-- {{editedItem}} -->
                          <v-col cols="12" sm="12" md="12">
                            <v-text-field
                              v-model="editedItem.password"
                              hide-details="auto"
                              :rules="editedItem.id ? undefined : requiredRules"
                              label="Password"
                              type="password"
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.firstName"
                              hide-details="auto"
                              :rules="requiredRules"
                              label="First Name"
                            ></v-text-field>
                          </v-col>

                          <v-col cols="12" sm="6" md="6">
                            <v-text-field
                              v-model="editedItem.lastName"
                              hide-details="auto"
                              :rules="requiredRules"
                              label="Last Name"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            class="custom-error-handler-top-item"
                          >
                            Direct Contact Number
                            <VuePhoneNumberInput
                              placeholder="Primary Contact Number"
                              v-model="editedItem.phone"
                              required
                              no-country-selector
                              :only-countries="['US']"
                              class="mb-6"
                              @input="updateValue()"
                            />
                            <v-text-field
                              v-model="editedItem.phone"
                              hide-details="auto"
                              :rules="requiredPhoneRules"
                              class="custom-error-handler-input mt-3"
                              disabled
                            ></v-text-field>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" v-if="isAdmin">
                            <v-checkbox
                              v-model="editedItem.showPrice"
                              label="Show Prices"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="6" md="4" v-if="isAdmin">
                            <v-checkbox
                              v-model="editedItem.activated"
                              label="Activated"
                            ></v-checkbox>
                          </v-col>
                          <v-col cols="12" sm="12" md="12" v-if="isAdmin">
                            <v-select
                              :items="roles"
                              v-model="editedItem.role"
                              label="Role"
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            v-if="
                              isAdmin &&
                                editedItem.role &&
                                (editedItem.role == 4 || editedItem.role == 6)
                            "
                          >
                            <v-select
                              :items="
                                !customers
                                  ? [
                                      {
                                        text: 'None',
                                        value: null
                                      }
                                    ]
                                  : [
                                      {
                                        text: 'None',
                                        value: null
                                      }
                                    ].concat(
                                      customers.map(item => {
                                        return {
                                          text: item.name,
                                          value: parseInt(item.id)
                                        };
                                      })
                                    )
                              "
                              hide-details="auto"
                              :rules="requiredCustomerRules"
                              v-model="editedItem.customer"
                              label="Client"
                              outlined
                            ></v-select>
                          </v-col>
                          <v-col
                            cols="12"
                            sm="12"
                            md="12"
                            v-if="
                              isAdmin && editedItem.role && editedItem.role == 5
                            "
                            class="driver-color-picker"
                          >
                            <v-color-picker
                              v-model="color"
                              show-swatches
                              hide-inputs
                              flat
                            ></v-color-picker>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-form>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close"
                      >Cancel</v-btn
                    >
                    <v-btn color="blue darken-1" text @click="save(data)"
                      >Save</v-btn
                    >
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-toolbar>
          </template>
          <template v-slot:item.firstName="{ item }">
            {{ item.firstName }}
          </template>
          <template v-slot:item.lastName="{ item }">
            {{ item.lastName }}
          </template>
          <template v-slot:item.orders="{ item }">
            {{ item.orders ? item.orders.length : 0 }}
          </template>
          <template v-slot:item.role="{ item }">
            {{ getRoleName(item.role) }}
          </template>
          <template v-slot:item.customer="{ item }">
            {{ item.customer ? item.customer.name : "-" }}
          </template>
          <template v-slot:item.showPrice="{ item }">
            {{ item.showPrice ? "yes" : "no" }}
          </template>
          <template v-slot:item.activated="{ item }">
            {{ item.activated ? "yes" : "no" }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  v-if="item.id != user.id"
                  @click="startChat(item)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-chat</v-icon
                >
              </template>
              <span>Start Chat</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  class="mr-2"
                  @click="editItem(item)"
                  v-bind="attrs"
                  v-on="on"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Edit User</span>
            </v-tooltip>

            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  small
                  @click="deleteItem(item)"
                  v-if="
                    (isAdmin || isClientAdmin) &&
                      item.id != user.id &&
                      item.activated != false
                  "
                  v-bind="attrs"
                  v-on="on"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Disable User</span>
            </v-tooltip>
          </template>
          <template v-slot:no-data>
            <v-btn color="primary" @click="initialize">Reset</v-btn>
          </template>
        </v-data-table>
      </div>

      <!-- No result -->
      <div v-else class="no-result apollo">No results</div>
    </template>
    <v-snackbar v-model="snackbar">
      {{ notification }}
      <template v-slot:action="{ attrs }">
        <v-btn color="#fc4b6c" text v-bind="attrs" @click="snackbar = false"
          >Close</v-btn
        >
      </template>
    </v-snackbar>
  </section>
</template>
<script>
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dialog: false,
    //
    search: "",
    role: null,
    customer: null,
    activated: true,
    //
    type: "hex",
    hex: "#2E7D32",
    // headers: ,
    roles: [
      {
        text: "Not Approved",
        value: 1
      },
      {
        text: "Admin",
        value: 3
      },
      {
        text: "Driver",
        value: 5
      },
      {
        text: "ClientUser",
        value: 4
      },
      {
        text: "ClientAdmin",
        value: 6
      }
    ],
    editedIndex: -1,
    editedItem: {},
    defaultItem: {
      role: 1,
      showPrice: false,
      activated: true,
      confirmed: true
    },
    //
    lookingForChat: false,
    //
    valid: true,
    //  hide-details="auto"
    // :rules="requiredRules"
    requiredRules: [v => !!v || "Field is required"],
    //
    requiredCustomerRules: [v => !!v || "Field is required"],
    requiredPhoneRules: [
      v => !!v || "Field is required",
      v => (v && v.length >= 14) || "Incorrect phone number"
    ],
    //
    notification: "",
    snackbar: false,
    //
    users: [],
    customers: [],
    usersLoaded: true
  }),
  apollo: {
    Users: {
      prefetch: true,
      query: require("@/graphql/Users.gql"),
      fetchPolicy: "cache-and-network",
      pollInterval: 15000,
      update: data => data,
      result(data) {
        if (!data.data) return;
        try {
          const { parse, stringify } = require("flatted/cjs");
          localStorage.setItem("users", stringify(data.data.users));
        } catch (err) {
          console.log("error with cache", err);
        }
        this.users = data.data.users;
        this.customers = data.data.customers;
        this.usersLoaded = true;
      }
    },
    Chats: {
      prefetch: true,
      fetchPolicy: "cache-and-network",
      query: require("@/graphql/ChatsList.gql"),
      update: data => data
    }
  },
  computed: {
    color: {
      get() {
        return this[this.type];
      },
      set(v) {
        this[this.type] = v;
      }
    },
    showColor() {
      if (typeof this.color === "string") return this.color;

      return JSON.stringify(
        Object.keys(this.color).reduce((color, key) => {
          color[key] = Number(this.color[key].toFixed(2));
          return color;
        }, {}),
        null,
        2
      );
    },
    formTitle() {
      return this.editedIndex === -1 ? "New User" : "Edit User";
    },
    headers() {
      return [
        {
          text: "First Name",
          align: "start",
          value: "firstName",
          filterable: false,
          sortable: true
        },
        {
          text: "Last Name",
          align: "start",
          value: "lastName",
          filterable: false,
          sortable: true
        },
        {
          text: "Email",
          value: "email",
          filterable: false,
          sortable: true
        },
        {
          text: "Phone",
          value: "phone",
          filterable: false
        },
        {
          text: "Orders",
          value: "orders",
          roles: ["Admin"],
          filterable: false
        },
        { text: "Role", value: "role", filterable: false },
        {
          text: "Client",
          value: "customer",
          roles: ["Admin"],
          filterable: false
        },
        {
          text: "Show Price",
          value: "showPrice",
          roles: ["Admin"],
          filterable: false,
          sortable: true
        },
        {
          text: "Activated",
          value: "activated",
          roles: ["Admin"],
          filterable: false
        },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          filter: this.searchFilter
        }
      ].filter(item => !item.roles || item.roles.includes(this.user.role.name));
    },
    ...mapGetters(["user", "strapi", "isAdmin", "isClientAdmin"])
  },

  watch: {
    dialog(val) {
      val || this.close();
    },
    "editedItem.role"(value) {
      if (value != 4 && value != 6) {
        this.editedItem.customer = -1;
      }
      // else {
      // this.editedItem.customer = null;
      // }
    }
  },

  created() {
    this.initialize();
  },
  mounted() {
    if (
      this.user.role.name == "ClientUser" ||
      this.user.role.name == "ClientAdmin"
    ) {
      this.defaultItem.customer = this.user.customer
        ? parseInt(this.user.customer.id)
        : undefined;
      this.defaultItem.role = 4;
      // Object.assign(this.editedItem, this.defaultItem);

      this.editedItem = this.lodash.cloneDeep(this.defaultItem);
    }

    try {
      const { parse, stringify } = require("flatted/cjs");
      let usersCached = parse(localStorage.getItem("users"));
      if (usersCached) {
        this.users = usersCached;
        this.usersLoaded = true;
      }
    } catch (err) {
      console.log("error with cache", err);
    }
  },
  methods: {
    formatPhoneNumber(str) {
      //Filter only numbers from the input
      let cleaned = ("" + str).replace(/\D/g, "");

      //Check if the input is of correct length
      let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);

      if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
      }

      return null;
    },
    updateValue() {
      var valClean = this.editedItem.phone
        ? this.editedItem.phone.replace(/\D/g, "")
        : this.editedItem.phone;
      if (valClean && valClean.length > 10) {
        this.editedItem.phone = this.formatPhoneNumber(
          valClean.substring(0, 10)
        );
        this.$forceUpdate();
      }
    },
    getRoleName(role) {
      if (!role) return "-";
      for (let i = 0; i < this.roles.length; i++) {
        if (this.roles[i].value == role.id) return this.roles[i].text;
      }
    },
    searchFilter(_value, _search, item) {
      if (item.activated != this.activated) return false;

      if (this.role) {
        if (!item.role || parseInt(item.role.id) != this.role) return false;
      }

      if (this.customer) {
        if (!item.customer || parseInt(item.customer.id) != this.customer)
          return false;
      }

      var searchWord = this.search.toLowerCase();
      if (
        (item.firstName && item.firstName.toLowerCase().includes(searchWord)) ||
        (item.lastName && item.lastName.toLowerCase().includes(searchWord)) ||
        (item.phone && item.phone.toLowerCase().includes(searchWord)) ||
        (item.email && item.email.toLowerCase().includes(searchWord))
      )
        return true;
      return false;
    },
    initialize() {
      // Object.assign(this.editedItem, this.defaultItem);

      this.editedItem = this.lodash.cloneDeep(this.defaultItem);
    },
    async startChat(item) {
      if (this.lookingForChat) return;
      this.lookingForChat = true;
      var chatFound = undefined;
      var chatsCached = this.Chats && this.Chats.chats ? this.Chats.chats : [];
      for (let i = 0; i < chatsCached.length; i++) {
        if (!chatsCached[i].users) continue;
        if (
          chatsCached[i].users
            .map(item => item.email)
            .join(", ")
            .toLowerCase()
            .includes(item.email.toLowerCase())
        ) {
          chatFound = chatsCached[i];
          break;
        }
      }

      // console.log(chatFound);

      try {
        if (!chatFound) {
          chatFound = await this.strapi.createEntry("chats", {
            users: [this.user.id, parseInt(item.id)]
          });
        }
        this.lookingForChat = false;
      } catch (err) {
        this.lookingForChat = false;
        return;
      }

      this.$router.push({ name: "ChatPage", query: { id: chatFound.id } });
    },
    editItem(item) {
      this.editedIndex = this.users.findIndex(i => i.id == item.id);
      // Object.assign(this.editedItem, item);

      this.editedItem = this.lodash.cloneDeep(this.users[this.editedIndex]);
      this.editedItem.role = parseInt(item.role.id);

      this.editedItem.customer = this.users[this.editedIndex].customer;
      // console.log(this.editedItem.customer);
      this.editedItem.customer = this.editedItem.customer
        ? parseInt(this.editedItem.customer.id)
        : undefined;
      // console.log(this.editedItem.customer);
      if (this.editedItem.color) this.hex = this.editedItem.color;

      this.dialog = true;
    },

    async deleteItem(item) {
      const index = this.users.findIndex(i => i.id == item.id);
      var result = confirm("Are you sure you want to delete this item?");
      if (result) {
        // await this.strapi.deleteEntry("users", item.id);
        var updatedItem = await this.strapi.updateEntry(
          "users",
          parseInt(item.id),
          {
            activated: false
          }
        );
        Object.assign(this.users[index], updatedItem);
      }
    },

    close() {
      this.dialog = false;
      this.$nextTick(() => {
        // Object.assign(this.editedItem, this.defaultItem);

        this.editedItem = this.lodash.cloneDeep(this.defaultItem);
        this.editedIndex = -1;
      });
    },

    async save(data) {
      this.$refs.form.validate();
      if (this.$refs.form.validate(true)) {
        this.editedItem.color = this.showColor;
        this.editedItem.email = this.editedItem.email.toLowerCase();
        this.editedItem.username = this.editedItem.email.toLowerCase();

        if (
          this.user.role.name == "ClientUser" ||
          this.user.role.name == "ClientAdmin"
        ) {
          this.editedItem.customer = this.user.customer
            ? parseInt(this.user.customer.id)
            : undefined;
        }

        if (this.editedIndex > -1) {
          try {
            var updatedItem = await this.strapi.updateEntry(
              "users",
              parseInt(this.editedItem.id),
              this.editedItem
            );
            Object.assign(this.users[this.editedIndex], updatedItem);
            this.close();
          } catch (err) {
            this.notification = "Error occured";
            this.snackbar = true;
          }
        } else {
          try {
            var newItem = await this.strapi.createEntry(
              "users",
              this.editedItem
            );
            this.users.push(newItem);
            this.close();
          } catch (err) {
            this.notification = "The user with this email already exists";
            this.snackbar = true;
          }
        }
      }
    }
  }
};
</script>
<style lang="scss" scoped></style>
